<template>
  <div id="store">
    <v-container fluid grid-list-md class="pa-4">
      <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
        <v-col>
          <user-profile-card></user-profile-card>
          <div class="text-center mt-3 mb-2">
            <support-us></support-us>
          </div>
          <div class="text-center">
            <feedback></feedback>
          </div>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import UserProfileCard from '../../components/users/UserProfileCard';
import SupportUs from '../../components/shared/SupportUs';
import Feedback from '../../components/shared/Feedback';

export default {
  name: 'store',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback
  }
};
</script>
